import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"

import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  arrows: false
}


const Carousel = () => {
  const data = useStaticQuery(graphql`
     query {
      image1: file(sourceInstanceName: { eq: "product" }, name: { eq: "carousel6" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image2: file(sourceInstanceName: { eq: "product" }, name: { eq: "carousel5" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image3: file(sourceInstanceName: { eq: "product" }, name: { eq: "carousel1" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
      image4: file(sourceInstanceName: { eq: "product" }, name: { eq: "carousel2" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <SliderWrapper>
      <Slider {...settings} className="overflow-hidden">
        <Img fluid={data.image1.childImageSharp.fluid} />
        <Img fluid={data.image2.childImageSharp.fluid} />
        <Img fluid={data.image3.childImageSharp.fluid} />
        <Img fluid={data.image4.childImageSharp.fluid} />
      </Slider>
</SliderWrapper>

  )
}

export default Carousel;

const SliderWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    height: 400px;
  }
  height: 600px;
  width: auto;
`
