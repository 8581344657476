import React from "react"
import Collapsible from "react-collapsible"
import styled from "styled-components"

const FaqItem = ({ title, children }) => (
  <Wrapper>
    <Collapsible
      className="faq"
      openedClassName="faq active"
      triggerClassName="faq-title"
      triggerOpenedClassName="faq-title active"
      triggerTagName="button"
      contentInnerClassName="faq-content"
      trigger={title}
      transitionTime={300}
      easing="ease-out"
    >
      {children}
    </Collapsible>
  </Wrapper>
)

const Wrapper = styled.div`
  &:hover {
    cursor: pointer;
  }
  .faq {
    border-bottom: 2px solid ${(props) => props.theme.color.black.lighter};
  }
  .faq-title {
    border: none;
    background: none;
    padding: 0;
    outline: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    font-family: ${(props) => props.theme.font.secondary};
    ${(props) => props.theme.font_size.regular};
    font-weight: bold;
    padding: 20px;
    padding-right: 42px;
    padding-left: 16px;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-left: 2px solid ${(props) => props.theme.color.black.regular};
      border-bottom: 2px solid ${(props) => props.theme.color.black.regular};
      position: absolute;
      top: 34px;
      right: 36px;
      transform: rotate(-45deg);
      transition: transform 0.3s ease-in-out;
    }
    &.active {
      &::after {
        transform: rotate(135deg);
      }
    }
  }
  .faq-content {
    padding: 0 6px 32px 16px;
    line-height: 26px;
    ${(props) => props.theme.font_size.small};
    color: ${(props) => props.theme.color.black.light};
  }
`

export default FaqItem
