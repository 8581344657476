import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from '../marker/Marker'
require("dotenv").config();

const defaultProps = {
    center: {
        lat: 53.4041,
        lng: 23.4964,
    },
    zoom: 16,
}

const GoogleMap = () => (
    <div style={{ height: '60vh', width: '90%', border: 'black solid 2px', }}>
        <GoogleMapReact
            bootstrapURLKeys={{
                key: process.env.GATSBY_GOOGLE_MAP_KEY,
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
        >
            <Marker
                lat={53.4041397}
                lng={23.4964329}

                text="Joachima Lelewela 2/2"
            />
        </GoogleMapReact>
    </div >
)

export default GoogleMap
