import React from "react"
import styled from "styled-components"

import { Section, Container } from "../global"

const Features = () => (
  <Section id="our services">
    <StyledContainer>
      <Subtitle>5 YEARS WARRANTY!</Subtitle>
      <SectionTitle>Our Services</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Demolition</FeatureTitle>
          {/*<FeatureText>*/}
          {/*  Concrete structures <br /> can be fine-crushed at source.*/}
          {/*</FeatureText>*/}
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Tiles Installation</FeatureTitle>
          {/*<FeatureText>*/}
          {/*  It allows for intricate tile patterns to be <br /> assembled and installed creating beautiful tile mosaics.*/}
          {/*</FeatureText>*/}
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Large Format Tiles Installation</FeatureTitle>
          {/*<FeatureText>*/}
          {/*  Select the tile format <br /> which you want them to be laid.*/}
          {/*</FeatureText>*/}
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Mesh Installation</FeatureTitle>
          {/*<FeatureText>*/}
          {/*  Choose a professional <br /> to install your floor-heating system.*/}
          {/*</FeatureText>*/}
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Ditra Installation</FeatureTitle>
          {/*<FeatureText>*/}
          {/*  We are able to provide you <br /> a successful waterproof installation.*/}
          {/*</FeatureText>*/}
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Drywall Installation</FeatureTitle>
          {/*<FeatureText>*/}
          {/*  We employ proper stone and drywall<br /> installation techniques.*/}
          {/*</FeatureText>*/}
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Heating floor Installation</FeatureTitle>
          {/*<FeatureText>*/}
          {/*  We employ proper stone and drywall<br /> installation techniques.*/}
          {/*</FeatureText>*/}
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Waterproof Installation</FeatureTitle>
          {/*<FeatureText>*/}
          {/*  We employ proper stone and drywall<br /> installation techniques.*/}
          {/*</FeatureText>*/}
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${(props) => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

const Subtitle = styled.h2`
  font-size: 3em;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 12px;
  text-align: center;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    font-size: 2em;
  }
`

const FeaturesGrid = styled.div`
  max-width: 670px;
  align-items: top;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  text-align: center;
  color: ${(props) => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
`

const FeatureText = styled.p`
  text-align: center;
`
