import React from "react"

import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Navigation from "../components/common/navigation/navigation"
import CookieConsent from "react-cookie-consent";
import Header from "../components/sections/header"
import Features from "../components/sections/features"
import Footer from "../components/sections/footer"
import Faq from "../components/sections/faq"
import About from "../components/sections/about"
import ContactPage from "../components/sections/contact"
import Carousel from "../components/sections/carousel"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Navigation />
    <Header />
    <Features />
    <About />
    <Faq />
    <ContactPage />
    <CookieConsent


      location="bottom"
      buttonText="I Accept"
      cookieName="gatsby-gdpr-google-analytics"
      style={{
        background: "#ABA8AF", opacity: "0.8"
      }}

      buttonStyle={{ background: "#ffd502", color: "#000000", fontSize: "16px", }}

    >
      We use cookies to improve your browsing experience on our site.{" "}
      <span style={{ fontSize: "12px", opacity: "0.7" }}>
        Please also read our Privacy Notice. By choosing I Accept, you consent to our use of cookies.
            </span>
    </CookieConsent>
    <Footer />
  </Layout>
)

export default IndexPage
