import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import ExternalLink from "../../components/common/ExternaLink"
import { Container } from "../global"
import logo from "../../images/favicon-logo2.png"
import FacebookIcon from "../../images/facebook.svg"
import InstagramIcon from "../../images/instagram.svg"

const SOCIAL = [
  {
    icon: FacebookIcon,
    link: "https://www.facebook.com/LT-Tiles-105461858857322",
  },
  {
    icon: InstagramIcon,
    link: "https://www.instagram.com/",
  },
]

const Footer = () => (
  <FooterWrapper id="o mnie">
    <BrandContainer>
      <LogoLink>
        <img src={logo} alt="logo" />
      </LogoLink>
      <Logo>LT Tiles</Logo>
    </BrandContainer>
    <LinkWrapper>
      <Link to="/policy-privacy">Privacy Policy</Link>
    </LinkWrapper>
    <FooterColumn>
      <IconWrapper>
        {SOCIAL.map(({ icon, link }) => (
          <ExternalLink key={link} href={link}>
            <img src={icon} alt="link" />
          </ExternalLink>
        ))}
      </IconWrapper>
      <TextWrapper>© 2022 LT Tiles. All rights reserved</TextWrapper>
    </FooterColumn>
  </FooterWrapper>
)

const IconWrapper = styled.div`
  display: flex;
  margin-left: 1em;
  justify-content: center;
  img {
    margin: 10px 10px;
    width: 25px;
    height: 25px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-top: 10px;
  }
`

const LinkWrapper = styled.div`
  margin-left: 1.2em;
  margin-top: 0.2em;
  font-size: 0.9rem;
  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
    color: black;
  }
  display: flex;
  justify-content: center;
`

const TextWrapper = styled.div`
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
`

export const LogoLink = styled(Container)`
  margin: 0;
  padding: 0;
  width: 80px;
  height: 60px;
`

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 10px 0 0;
  padding: 0 0 0px;
`

const Logo = styled.div`
  font-family: ${(props) => props.theme.font.extrabold};
  ${(props) => props.theme.font_size.regular};
  color: ${(props) => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
  margin-left: 1em;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
  }
`

const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-family: ${(props) => props.theme.font.bold};
    color: ${(props) => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${(props) => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-family: ${(props) => props.theme.font.normal};
      font-size: 15px;
    }
  }
`

export default Footer
