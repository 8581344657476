import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import EmailIcon from "../../images/email-icon.png"
import PlaceIcon from "../../images/home-icon.png"
import PhoneIcon from "../../images/phone-icon.png"
import Carousel from "./carousel"

import { Container } from "../global"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "photo-withblob" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)


  return (
    <HeaderWrapper id="top">
      <Container>
        <Flex>
          <HeaderTextGroup>
            <FeatureList>
            <Subtitle>Tile Company from Mississauga</Subtitle>
            <h1>LT TILES</h1>
              <h2>The details are not the only details.</h2>
              <h2>They make the design</h2>
            </FeatureList>
            <FeatureList>
              <Feature>
                <img src={PhoneIcon} alt="phoneicon" />
                <FeatureTextWrapper><LinkWrapper href="tel:416 832 7380">416 832 7380</LinkWrapper></FeatureTextWrapper>
              </Feature>
              <Feature>
                <img src={EmailIcon} alt="emailicon" />
                <FeatureTextWrapper>
                  <LinkWrapper href="mailto: lucas.top.tiles@gmail.com" target="_blank">lucas.top.tiles@gmail.com</LinkWrapper>
                </FeatureTextWrapper>
              </Feature>

              <Link to="/gallery">
              <FeatureButton>Check Our Work!</FeatureButton>
              </Link>
            </FeatureList>
          </HeaderTextGroup>
          <ImageWrapper>
            <StyledImage>
              <Carousel/>
            </StyledImage>
            {/*<Carousel/>*/}
            {/*<StyledImage fluid={data.file.childImageSharp.fluid} />*/}
            {/*<br />*/}
          </ImageWrapper>
        </Flex>
      </Container>
    </HeaderWrapper>
  )
}

export default Header

const FeatureButton = styled.button`
  color: ${(props) => props.theme.color.primary};
  padding: 1em;
  margin-left: 8px;
  border-radius: 5px; 
  text-decoration: none;
  background-color: #ffd502;
  border: 3px solid black;
  font-weight: bolder;
  margin-left: 2.5em;
  margin-top: 3em;
`

const FeatureTextWrapper = styled.div`
  margin: 0 0 0 10px;
  padding: 0px;
  font-size: 17px;
`

const Feature = styled.li`
  padding: 20px 0px 5px 0px;
  display: flex;
  align-items: items-center;
  img {
    height: 30px;
    width: 30px;
  }
`

const FeatureList = styled.ul`
  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  padding: 0;
  line-height: 2;
  margin: 0px;
`

const HeaderWrapper = styled.header`
  background-color: #afafaf;
  padding: 145px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`
const Subtitle = styled.h5`
  margin-top: 45px;
  font-size: 20px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`

const HeaderTextGroup = styled.div`
  margin-top: 26px;

  > div {
    width: 120%;
    margin-bottom: -4.5%;

    @media (max-width: ${(props) => props.theme.screen.md}) {
      
      margin-left: 34px;
    }
  }

  h1 {
    margin: 0 0 14px;
    color: ${(props) => props.theme.color.primary};
  }

  h2 {
    margin-bottom: 12px;
    ${(props) => props.theme.font_size.regular}
  }

  p {
    margin-bottom: 48px;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 1fr 1fr;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
`

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  padding-bottom: 16px;

  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const LinkWrapper = styled.a`
  color: black;
  text-decoration: none;
`

// const FormSubtitle = styled.span`
//   ${(props) => props.theme.font_size.xxsmall}
// `

// const FormSubtitleLink = styled(Link)`
//   color: ${(props) => props.theme.color.secondary};
//   padding-bottom: 1px;
//   margin-left: 8px;
//   text-decoration: none;
//   border-bottom: 1px solid ${(props) => props.theme.color.secondary};
// `

// const HeaderInput = styled.input`
//   font-weight: 500;
//   font-size: 16px;
//   color: ${(props) => props.theme.color.primary};
//   line-height: 42px;
//   width: 100%;
//   text-align: left;
//   height: 60px;
//   border-width: 1px;
//   border-style: solid;
//   border-color: ${(props) => props.theme.color.secondary};
//   border-image: initial;
//   border-radius: 4px;
//   padding: 8px 16px;
//   outline: 0px;
//   &:focus {
//     box-shadow: inset ${(props) => props.theme.color.secondary} 0px 0px 0px 2px;
//   }
//   @media (max-width: ${(props) => props.theme.screen.md}) {
//     margin-bottom: 8px;
//   }
//   @media (max-width: ${(props) => props.theme.screen.sm}) {
//     display: block;
//     width: 100%;
//   }
// `

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${(props) => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;

  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`
const ImageWrapper = styled.div`
  justify-self: end;
  align-self: center;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    justify-self: center;
  }
`

const StyledImage = styled.div`
  border-radius: 2%;
  width: 400px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    max-width: 300px;
  }
`
