import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';

const animateBounce = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-2000px) rotate(-45deg);
    }

    60% {
        opacity: 1;
        transform: translateY(30px) rotate(-45deg);
    }

    80% {
        transform: translateY(-10px) rotate(-45deg);
    }

    100% {
        transform: translateY(0) rotate(-45deg);
    }
`

const animatePulse = keyframes`
    0% {
        transform: scale(0.1, 0.1);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1.2, 1.2);
        opacity: 0;
    }
`

const Pin = styled.div`    
    width: 30px;
    height: 30px;
    border-radius: 50% 50% 50% 0;
    background: #35A6DE;
    position: absolute;
    transform: rotate(-65deg);
    left: 50%;
    top: 50%;
    margin: -20px 0 0 -20px;


    ::after {
        content: "";
        width: 14px;
        height: 14px;
        margin: 8px 0 0 8px;
        background: #e6e6e6;
        position: absolute;
        border-radius: 50%;
    }

    &.bounce {
        animation-name: ${animateBounce};
        animation-fill-mode: both;
        animation-duration: 2s;
    }`

const Wrapper = styled.div`

`;

const Pulse = styled.div`
    /* background: #d6d4d4; */
 
    background: #d6d4d4;
    border-radius: 50%;
    height: 1px;
    width: 1px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: 11px 0px 0px -12px;
    transform: rotateX(35deg);
    z-index: -2;
    

    ::after {
        content: "";
        border-radius: 50%;
        height: 40px;
        width: 40px;
        position: absolute;
        margin: -13px 0 0 -13px;
        animation: ${animatePulse} 1s ease-out;
        animation-iteration-count: infinite;
        opacity: 0;
        box-shadow: 0 0 1px 2px #00cae9;
        animation-delay: 1.1s;
    }
`

const Marker = ({ text, onClick }) => (

    <Wrapper
        alt={text}
        onClick={onClick}><Pin className="bounce"></Pin><Pulse />
    </Wrapper>



);

Marker.defaultProps = {
    onClick: null,
};

Marker.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
};

export default Marker;