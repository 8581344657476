import React from "react"
import styled from "styled-components"

import { Container, Section } from "../global"
import FaqItem from "../../components/common/faq/faqitem"

const FAQS = [
  {
    title: "How can I contact you?",
    content: () => (
      <>
        You have plenty of options to contact us. Simply choose your preferred method and we will respond as quickly as possible.
        <ul>
          <li>Phone: 416 832 7380</li>
          <li>Email: lucas.top.tiles@gmail.com</li>
          <li>Messenger</li>
        </ul>
      </>
    ),
  },
  {
    title: "Will I get warranty?",
    content: () => (
      <>
        Sure, 5 year warranty!
      </>
    ),
  },
  {
    title: "What's the difference between a floor and wall tile?",
    content: () => (
      <>
        A floor tile can be used on both floor and walls, whereas a wall tile can only be used on the wall. This is generally because the floor tiles are thicker and more durable, while wall tiles are thinner. In some cases the finish on the tile may also be an influencing factor.
      </>
    ),
  },
  {
    title: "What difference does the body of a tile make?",
    content: () => (
      <>
        There are different body types for tiles and each have their benefits. The main body types we sell are either coloured body porcelain or white bodied ceramic.

        First thing to point out is the basic difference between a porcelain and ceramic tile. Porcelain is made with slightly different ingredients and is baked in the kiln for longer and at higher temperatures. This makes the porcelain tile more dense, heavier and less water absorption. Technically speaking, a tile is classified as porcelain when it's water absorption rate is equal to or less than 0.5%. If it's higher than this then it is considered to be ceramic.
      </>
    ),
  },
]

const Faq = () => (
  <StyledSection id="faq">
    <GetStartedContainer>
      <GetStartedTitle>Frequently Asked Questions</GetStartedTitle>
      <div>
        {FAQS.map(({ title, content }) => (
          <FaqItem title={title} key={title}>
            {content()}
          </FaqItem>
        ))}
      </div>
    </GetStartedContainer>
  </StyledSection>
)

export default Faq

const StyledSection = styled(Section)`
  background-color: ${(props) => props.theme.color.background.white};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

// const TryItButton = styled.button`
//   font-weight: 500;
//   font-size: 14px;
//   color: white;
//   letter-spacing: 1px;
//   height: 60px;
//   display: block;
//   margin-left: 8px;
//   text-transform: uppercase;
//   cursor: pointer;
//   white-space: nowrap;
//   background: ${(props) => props.theme.color.secondary};
//   border-radius: 4px;
//   padding: 0px 40px;
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   outline: 0px;
//   &:hover {
//     box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
//   }
//   @media (max-width: ${(props) => props.theme.screen.md}) {
//   }
//   @media (max-width: ${(props) => props.theme.screen.sm}) {
//     margin-left: 0;
//   }
// `

// const Subtitle = styled.span`
//   ${(props) => props.theme.font_size.xxsmall}
//   padding-top: 16px;
//   font-size: 14px;
//   color: ${(props) => props.theme.color.primary};
// `
