import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"
import CheckboxIcon from "../../images/checked.png"

import { Container } from "../global"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      file(sourceInstanceName: { eq: "product" }, name: { eq: "tile-blob" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <AboutWrapper id="about us">
      <Container>
        <Grid>
          <StyledImage fluid={data.file.childImageSharp.fluid} />
          <TextWrapper>
            <TitleWrapper >About us</TitleWrapper>
            <ParagraphWrapper>
               <b>LT Tiles</b> tile installation company with over 5 years of practical experience in installations & construction. This experience helps us guides our customers from idea to final installation. In our offer you will find many new and interesting solutions for installing tiles in your home.
            </ParagraphWrapper>
            <ParagraphWrapper>
              We value accuracy, precision and reliability - why we work with the best solutions and materials. Check out our offer and take advantage of a <QuoteText><FreeQuouteWrapper href="mailto: lucas.top.tiles@gmail.com" target="_blank">free qoute.</FreeQuouteWrapper></QuoteText>
            </ParagraphWrapper>
            <ExamplesWrapper>
            <FinishedTitle>We provide</FinishedTitle>
            <FeatureList>
              <Feature>
                <img src={CheckboxIcon} alt="checkboxicon" />
                <FeatureTextWrapper>
                  accuracy
                </FeatureTextWrapper>
              </Feature>
              <Feature>
                <img src={CheckboxIcon} alt="checkboxicon" />
                <FeatureTextWrapper>
                  precision
                </FeatureTextWrapper>
              </Feature>
              <Feature>
                <img src={CheckboxIcon} alt="checkboxicon" />
                <FeatureTextWrapper>
                  reliability
                </FeatureTextWrapper>
              </Feature>
              <Feature>
                <img src={CheckboxIcon} alt="checkboxicon" />
                <FeatureTextWrapper>
                  5 years warranty!
                </FeatureTextWrapper>
              </Feature>
            </FeatureList>
            </ExamplesWrapper>
          </TextWrapper>
        </Grid>
      </Container>
    </AboutWrapper>
  )
}

const ExamplesWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.screen.md}) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const QuoteText = styled.h2`
  margin: 0px;
  color: #ffd502;
  font-size: 2em;
`

const TitleWrapper = styled.h1`

  font-size: 3em;
  padding: 0px 0 0px 0;
  margin: 20 0 0 0px;
  text-align: left;
  @media (max-width: ${(props) => props.theme.screen.xs}) {
    text-align: center;
  }
`

const FeatureTextWrapper = styled.div`
  margin: 0 0 0 10px;
  padding: 0px;
  font-size: 1em;
  align-items: center;
`

const Feature = styled.li`
  padding: 5px 0px 5px 0px;
  display: flex;
  align-items: center;
  line-height: 1.5;
  img {
    align-items: center;
    height: 16px;
    width: 16px;
  }
`

const FinishedTitle = styled.h4`
  margin: 20px 45px 10px 0px;
`

const ParagraphWrapper = styled.p`
  font-size: 18px;
  margin-top: 10px;
`

const FeatureList = styled.ul`
  padding: 0;
  line-height: 2;
  margin: 0px;
`

const TextWrapper = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`

const StyledImage = styled(Img)`

  margin: 110px 0 40px 0;
  border-radius: 1%;
  width: 450px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
    width: 400px;
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    width: 300px;
    display: none;
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: left;
  align-items: center;
  justify-items: center;
  margin: 24px 0;
  ${(props) =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}
  h2 {
    margin-bottom: 16px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;
    &:last-child {
      margin-bottom: 24px;
    }
    ${(props) =>
    props.inverse &&
    `
        ${Art} {
          order: 2;
        }
    `}
  }
`

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`
const AboutWrapper = styled.div`
  background-color: #afafaf;
  padding: 0px 0 80px 0;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 5vw));
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const FreeQuouteWrapper = styled.a`
  color: #ffd502;
  text-decoration: none;
`

export default About
