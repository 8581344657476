import React from "react"
import styled from "styled-components";
import axios from 'axios';
import { Formik, ErrorMessage } from 'formik';
import { Container } from "../global"
import logo from "./../../images/favicon-logo2.png"

import GoogleMap from "../../components/common/googlemap/googlemap"
import PhoneIcon from "../../images/phone-icon.png"
import EmailIcon from "../../images/email-icon.png"


const ContactWrapper = styled.div`
  padding: 0px 0 20px 0;
  position: relative;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`

const StyledContainer = styled(Container)`
justify-content: center;
margin-top: 40px;
display: grid;
//grid-template-columns: 2fr 3fr;   #remove container with logo
grid-gap: 20px;
text-align: left;
align-items: center;
justify-items: center;
background-color: #afafaf;
@media (max-width: ${(props) => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;
    &:last-child {
      margin-bottom: 24px;
    }
    ${(props) =>
    props.inverse &&
    `
        ${Art} {
          order: 2;
        }
    `}
  }
`
const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`

const StyledInput = styled.input`
  display: block;
  border-radius: 10px;
  border: 2px solid black;
  
  background: none;
  font-size: 20px;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
  height: ${({ as }) => (as ? "250px" : "auto")};
  width: ${({ as }) => (as ? "340px" : "340px")};
  margin-bottom: ${({ as }) => as && "20px"};
  :focus{
      outline:none;
  }
`

const StyledLabel = styled.label`
  margin: 10px 0 10px;
  display: block;
  font-size: 15px;
  font-weight: bold;
`


const Button = styled.button`
height: ${({ as }) => (as ? "250px" : "auto")};
  width: ${({ as }) => (as ? "340px" : "340px")};
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 40px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: #217CB1;
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;

  &:hover {
    box-shadow: rgba(130, 120, 152, 0.44) 0px 2px 10px 0px;
  }
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const SectionWraper = styled.div`
    background-color: #afafaf;
    padding-top: 35px;
    padding-bottom: 25px;
    
`

const StyledTitle = styled.h3`
  text-align: center;
  margin: 0;

`

const StyledParagraph = styled.p`
  text-align: center;
`

const FeatureTextWrapper = styled.div`
  margin: 0 0 0 10px;
  padding: 0px;
  font-size: 17px;
`

const Feature = styled.li`
  padding: 20px 0px 5px 0px;
  display: flex;
  align-items: items-center;
  color: ${(props) => props.theme.color.primary};
  padding: 1em;
  margin-left: 8px;
  border-radius: 5px;
  text-decoration: none;
  background-color: #ffd502;
  border: 3px solid black;
  font-weight: bolder;
  margin-left: 2.5em;
  margin-top: 3em;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    margin: auto;
  }
  img {
    height: 30px;
    width: 30px;
  }
`

const FeatureList = styled.ul`
  padding: 0;
  line-height: 2;
  margin: 0px;
  display: flex;
  gap: 3em;
  margin-bottom: 3em;
  @media (max-width: ${(props) => props.theme.screen.sm}) {
    flex-direction: column;
    margin: auto;
    gap: 1em;
  }
`



const ContactPage = () => (
  <>
    <SectionWraper id="contact">
      <StyledContainer >
        <ContactWrapper >

            <StyledTitle>Contact Us</StyledTitle>
            <StyledParagraph>You will get an answer and a quote within 24 hours.<br/>
              We respect your time.</StyledParagraph>
          <FeatureList>
            <Feature>
              <img src={PhoneIcon} alt="phoneicon" />
              <FeatureTextWrapper><LinkWrapper href="tel:416 832 7380">416 832 7380</LinkWrapper></FeatureTextWrapper>
            </Feature>
            <Feature>
              <img src={EmailIcon} alt="emailicon" />
              <FeatureTextWrapper>
                <LinkWrapper href="mailto: lucas.top.tiles@gmail.com" target="_blank">lucas.top.tiles@gmail.com</LinkWrapper>
              </FeatureTextWrapper>
            </Feature>
          </FeatureList>
        </ContactWrapper>
      </StyledContainer></SectionWraper>
  </>
)

const LinkWrapper = styled.a`
  color: black;
  text-decoration: none;
`

export default ContactPage
